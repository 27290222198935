<template>
  <div class="wrapper static-article">
    <row>
      <column
        xs="12"
        md="2">
      </column>
      <column
        xs="12"
        md="8">
        <h1 class="ff-flama fs-xxl fw-light tt-uppercase mb-5">
          Personvernerklæring for Europower AS
        </h1>
        <div class="article-body clearfix mb-3">
          <p>
            Revisjon 1. juli 2018
          </p>
          <p>
            Denne personvernerklæringen gjelder for alle våre sluttbrukere. Vi ønsker at du skal ha mest mulig kontroll over dine personopplysninger og hvordan de behandles. I denne personvernerklæringen finner du informasjon om hva vi behandler om deg og kontaktinformasjon dersom du har spørsmål om vår behandling av personopplysninger.
          </p>
          <p class="mt-5">
            <strong>1. Om Europower, Tjenestene og de registrerte</strong>
          </p>
          <p>
            1.1 Hvem er vi?
          </p>
          <p>
            Denne personvernerklæringen gjelder når vi, Europower AS med org. nr. 916 055 137 og tilhørende publikasjoner som Energi, Energi-nett, Europower.com («Europower») behandler personopplysninger om deg. Vi tar ditt personvern på alvor og er opptatt av at du skal ha tillit til oss og hvordan vi behandler dine personopplysninger.
          </p>
          <p>
            Denne personvernerklæringen forklarer derfor hvorfor Europower samler inn informasjon om deg, hvordan denne informasjonen brukes og hvordan Europower tar hensyn til ditt personvern. Dine data er trygge hos oss og brukes kun til det som oppgis i denne erklæringen.
          </p>
          <p>
            Europower AS er heleid av DN Media Group AS («NHST»). NHST og dets datterselskaper omtales heretter samlet som «Konsernet». Informasjon om Konsernet finnes på <a href="https://www.nhst.no">www.nhst.no</a>. Siden Konsernet benytter samme basis informasjonssystemer og har felles tekniske plattformer og IT-avdeling, kan personopplysninger behandles av Konsernet for administrative formål.
          </p>
          <p>
            1.2 Tjenesten
          </p>
          <p>
            Personvernerklæringen forklarer hvordan Europower og Europowers samarbeidspartnere samler inn og bruker personopplysninger fra deg ved registrering på og bruk av Europowers fysiske magasiner («Magasinene»), nyhetsbrev («Nyhetsbrevene»), og nettsteder som websider tilknyttet Europower («Nettstedene»). Disse er heretter samlet omtalt som «Tjenestene». Denne personvernerklæringen forklarer også dine personvernsrelaterte rettigheter overfor Europower og Europowers samarbeidspartnere. Tjenestene eies og drives av Europower AS, med forretningsadresse Christian Krohgs gate 16, 0186 OSLO, postadresse Postboks 1182, 0107 OSLO.
          </p>
          <p>
            1.3 Hvem vi behandler personopplysninger om
          </p>
          <p>
            Europower har flere grupper med sluttbrukere. Hvis du benytter deg av Europowers Tjenester, vil du tilhøre en av disse gruppene. Som en sluttbruker og identifiserbar person som det behandles personopplysninger om, omtales du som «registrert» i personopplysningsloven. Hvilke personopplysninger som behandles og om hvilke brukere vil bli forklart nærmere under punkt 4.
          </p>
          <p>
            Abonnenter
          </p>
          <p>
            Den gruppen vi behandler mest personopplysninger om er abonnenter som betaler for Tjenestene eller får Tjenestene betalt av arbeidsgiver, og aktivt bruker Tjenestene.
          </p>
          <p>
            Passive abonnenter (tidligere abonnenter)
          </p>
          <p>
            Når et abonnement et er sagt opp og det har gått 12 måneder siden oppsigelsen, anses den registrerte som en passiv eller tidligere abonnent. Da vil vi i utgangspunktet beholde noen personopplysninger i en viss periode. Andre opplysninger og formål vil vi be om spesifikt samtykke for å få lov til å behandle videre.
          </p>
          <p>
            Registrert bruker uten abonnement
          </p>
          <p>
            Du kan også være en registrert bruker hos Europower tilknyttet en bedriftsavtale. Det vil være tilfelle hvis:
          </p>
          <p>
            - du mottar et Nyhetsbrev
          </p>
          <p>
            - arbeidsgiver har registrert deg for å få tilgang til Tjenesten
          </p>
          <p>
            Uregistrerte brukere
          </p>
          <p>
            De uregistrerte brukerne er de som er innom Tjenestene men hverken registrerer seg eller betaler for bruken. For denne gruppen brukeren behandler Europower svært få opplysninger som kan knyttes til deg som person.
          </p>
          <p>
            Hvilke personopplysninger som behandles og om hvilke brukere vil bli forklart nærmere nedenfor.
          </p>
          <p class="mt-5">
            <strong>2. Europower behandler personopplysninger til disse formålene</strong>
            <ol>
              <li>
                For å kunne levere og fakturere Tjenestene til deg.
              </li>
              <li>
                For å sikre at bruk av Tjenestene er i henhold til vilkårene.
              </li>
              <li>
                For å opprettholde og videreutvikle Tjenestene.
              </li>
              <li>
                For å kommunisere med deg om endringer og annen viktig informasjon knyttet til avtaleforholdet eller redaksjonelt innhold og tilbud.
              </li>
              <li>
                For å kunne levere, tilpasse og måle effekten av annonser.
              </li>
            </ol>
          </p>

          <p>
            Nærmere om formål nummer 1:
          </p>
          <p>
            Europower behandler dine personopplysninger for å kunne tilby deg Tjenestene og oppfylle eventuelle kundeavtaler vi har med deg. Dette inkluderer for eksempel å gi deg tilgang til Tjenestene, gi deg tilgang til å administrere din bruker og kundeforhold, levere aviser, fakturere/purre på betalinger og å informere deg om sentrale forhold knyttet til ditt kundeforhold eller Tjenestene.
          </p>
          <p>
            Nærmere om formål nummer 2:
          </p>
          <p>
            Opplysninger om deg brukes også til å forhindre misbruk av Tjenestene. Misbruk kan være forsøk på å logge på andres kontoer, forsøk på å utnytte innholdet i Tjenestene i kommersiell sammenheng, forsøk på svindel, “spamming”, hets, sjikane og andre handlinger som er forbudt etter norsk lov eller er i strid mot våre vilkår for bruk av Tjenestene.
          </p>
          <p>
            Nærmere om formål nummer 3:
          </p>
          <p>
            Europower bruker opplysninger om deg og din bruk av Tjenestene til å sikre en best mulig og fortrolig brukeropplevelse, samt for å lære mer om hvordan vi kan utvikle Tjenestene videre.
          </p>
          <p>
            Nærmere om formål nummer 4:
          </p>
          <p>
            Vi bruker opplysninger om deg til direkte kommunikasjon og markedsføring i kanaler som epost, sms, brev og telefon, med mindre du har meldt deg av slik kommunikasjon der det er mulig. Enkelte dialoger fra oss kan du ikke velge å melde deg av hvis du har registrert deg som abonnent eller for et annet kundeforhold. Et eksempel på det er dialoger knyttet til betaling.
          </p>
          <p>
            Nærmere om formål nummer 6:
          </p>
          <p>
            Europower er delvis finansiert av annonseinntekter og er derfor avhengig av å levere, tilpasse og måle effekten av annonser og annen markedsføring i Tjenestene. Dette gjøres internt i Europower, innad i Konsernet og i samarbeid med tredjeparter som Google Ireland Ltd, som nærmere beskrevet under punktene 4-6 og i avsnittene her.
          </p>
          <p>
            Europower bruker opplysninger om deg til direkte kommunikasjon og markedsføring i tradisjonelle kanaler som epost, sms, brev og telefon, med mindre du har meldt deg av slik kommunikasjon eller på annen måte trekker ditt samtykke.
          </p>
          <p>
            Opplysningene kan også brukes av oss og våre annonsører begrenset til Tjenestene til profil- og segmentbasert markedsføring, utvikling og videreutvikling av målrettede markedsføring, programmatisk annonsering, automatisk markedsføring og såkalt «retargeting». Med «programmatisk annonsering» menes automatisert kjøp og salg av nettannonser via en annonsebørs hvor vi legger annonseplasser (visninger) ut for salg på annonsebørsen, og annonsører kan legge inn bud på visninger basert på annonsørens kriterier. Når alle kriteriene for annonsekjøpet stemmer, vil annonsekjøpet skje automatisk slik at annonsen vises i sanntid. Med «automatisk markedsføring» menes markedsføring som skjer ved hjelp av digitale verktøy basert på kriterier fastsatt av oss og våre samarbeidspartnere. Med «retargeting» menes her gjentatte og målrettede digitale annonser mot den samme sluttbrukeren.
          </p>

          <p class="mt-5">
            <strong>3. Oversikt over Europowers behandlingsgrunnlag</strong><br>
            Enhver behandling av personopplysninger trenger et lovlig grunnlag, også kjent som et behandlingsgrunnlag. Det betyr at før Europower starter en behandling av dine personopplysninger, må vi påse at vi har rett til å utføre behandlingen (at Europower har et grunnlag). Det grunnlaget kan for eksempel komme direkte fra deg i form av et frivillig samtykke eller en avtale med oss. Her forklares hvilke grunnlag som brukes av Europower for å behandle personopplysninger og når de brukes.
          </p>
          <p>
            Europower verken kan eller vil behandle andre personopplysninger om deg enn de som uttrykkelig angis og heller ikke på andre måter enn det som beskrives i denne erklæringen.
          </p>
          <p>
            3.1 Avtale
          </p>
          <p>
            Dersom du er en abonnent eller registrert bruker hos Europower, så må Europower kunne behandle personopplysninger om deg for å kunne oppfylle brukeravtalen. Det er kun den behandlingen som er nødvendig for å oppfylle Europowers plikter etter avtalen som blir gjennomført med dette behandlingsgrunnlaget.
          </p>
          <p>
            Hvilke behandlinger som er nødvendig for å oppfylle avtalen, vil avhenge av brukeravtalen mellom deg og Europower. Brukeravtalen vil for eksempel være «Abonnementsvilkår» eller «Vilkår for registrert bruker uten abonnement». For sluttbrukere med abonnement vil avtalen variere noe basert på hva slags type abonnement man inngår avtale om.
          </p>
          <p>
            Et eksempel på en behandling med grunnlag i avtale: Dersom du har et abonnement hos Europower om levering av magasin, må Europower vite hvilken adresse avisen skal leveres til. Et annet eksempel er at Europower må behandle personopplysninger for å få utsendt riktig faktura og påse at den blir betalt.
          </p>
          <p>
            3.1.1 Nyhetsbrev og varsler
          </p>
          <p>
            Europower har også nyhetsbrev fra redaksjonen du som registrert bruker kan velge å melde deg av og på. Vi leverer nyhetsbrevene som en tjeneste slik det er beskrevet i avtalevilkårene og av- og påmeldingen styrer du selv via selve nyhetsbrevet.
          </p>
          <p>
            3.2 Legitim interesse
          </p>
          <p>
            I tillegg har Europower legitim interesse for noen behandlinger som annonseformål, markedsføringsformål og lignende. Slik behandling med grunnlag i legitim interesse gjelder for brukere med en registrert profil eller et abonnement, eller passive brukere (tidligere abonnementsinnehavere som beskrevet i punkt 1.3).
          </p>
          <p>
            Du kan lese mer om vurderingen Europower har gjort av ditt personvern vurdert opp mot Europowers legitime interesse, de implementerte tiltakene for å redusere risikoen med behandlingen og konklusjonen om legitim interesse i vedlegg 1.
          </p>
          <p>
            Du har en innsigelsesrett. Det vil si at du har adgang til å reservere deg mot behandlingen slik som beskrevet i punkt 8. Hvis du ikke ønsker at Europower skal foreta behandlingen kan du kontakte oss via <a href="/kontaktoss/">www.europower.no/kontaktoss</a>
          </p>
          <p>
            Europower har ikke vilkår knyttet til slik utmelding, men Europower gjør oppmerksom på at brukeropplevelsen på tjenestene kan bli dårligere for de brukerne som benytter sin innsigelsesrett.
          </p>
          <p>
            3.3 Samtykke
          </p>
          <p>
            Du står fritt til å bruke Tjenestene uten å gi oss samtykke til å behandle personopplysninger om deg. Enhver brukergruppe vil kunne samtykke til forskjellig behandlingsaktiviteter fra oss.
          </p>
          <p>
            For eksempel, som abonnent eller annen registrert bruker med en brukerprofil vil du kunne velge å reservere deg mot behandling med grunnlag i vår legitime interesse.
          </p>
          <p>
            Dersom andre situasjoner oppstår som benytter samtykke som behandlingsgrunnlag, vil du bli informert om at du er i ferd med å samtykke til en behandlingsaktivitet. Samtykket kan trekkes tilbake slik det er beskrevet i punkt 8.
          </p>
          <p>
            3.3.1 Informasjonskapsler (Cookies)
          </p>
          <p>
            Europower bruker informasjon som vi samler fra egne og tredjeparters informasjonskapsler, sporingspiksler og annen lagringsteknologi som er integrert i Tjenestene. Denne behandlingen gjelder for alle grupper sluttbrukere på de relevante Tjenestene. Når du går inn på og tar i bruk Nettstedene eller Nyhetsbrevene, eller på annen måte bruker Tjenestene, godtar du at Europower, Konsernet og Europowers samarbeidspartnere behandler opplysninger om deg som beskrevet i denne personvernerklæringen.
          </p>
          <p>
            Dersom din nettleser tillater informasjonskapsler, såkalte cookies, ansees dette som at du har gitt oss samtykke til at vi kan bruke informasjonskapsler som angitt i denne personvernerklæringen. Se også vår informasjonskapselerklæring. Vi opplyser imidlertid om at visse deler av Tjenestenes funksjonalitet avhenger av slikt samtykke og at din tilgang til og brukeropplevelse av Tjenestene derfor vil kunne bli påvirket.
          </p>
          <p>
            3.4 Lov
          </p>
          <p>
            Visse behandlinger av personopplysninger er Europower lovpålagt å foreta. Dette vil for eksempel være eventuelle personopplysninger som Europower må behandle for å overholde sin regnskaps- og bokføringsplikt.
          </p>
          <p class="mt-5">
            <strong>4. Hvilke opplysninger som behandles</strong>
          </p>
          <p>
            4.1 Opplysninger som behandles om alle som bruker Tjenestene:
          </p>
          <p>
            - Tilgjengelig informasjon om din enhet og dens internettilkobling, som for eksempel navn på mobil-modell, operativsystem og nettleserversjon, epostklient, samt informasjon om tilkoblingen til Tjenestene, som IP-adresser, nettverks-id, informasjonskapsler og unike identifikasjonsfiler.
          </p>
          <p>
            - Statistikk om din bruk av Tjenestene, som for eksempel hvor ofte ulike knapper eller lenker trykkes på, kjøp som blir fullført, hvilke sider som besøkes, når og hvor lenge disse besøkes, «Cross-device»-tracking for innloggede brukere, osv.
          </p>
          <p>
            - Statistikk om interaksjon med annonser, nyhetsbrev og spørreundersøkelser som vises i Tjenestene.
          </p>
          <p>
            - Lokasjonen til enheten du bruker, dersom slik informasjon deles av enheten.
          </p>
          <p>
            Informasjonen nevnt i punktene over er i utgangspunktet ikke knyttet til din identitet, men behandles som avidentifiserte eller pseudonymiserte opplysninger om én av Europowers brukere. Du kan begrense slik lagring ved å surfe i såkalte «Private Mode»/«Incognito Mode» eller ved å bruke «Do Not Track»-funksjon i din nettleser.
          </p>
          <p>
            4.2 Opplysninger som behandles om forskjellige sluttbrukere
          </p>
          <p>
            Dersom du registrerer deg som abonnent eller bruker av Tjenestene, vil eventuell lagret informasjon som nevnt i punktene over bli knyttet til ditt kundeforhold eller din bruker. I tillegg til de opplysningene som er beskrevet i punkt 4.1, kan vi behandle personopplysninger om de forskjellige sluttbrukere slik det er beskrevet her.
          </p>
          <p>
            4.2.1 Opplysninger som behandles om abonnenter
          </p>
          <p>
            Vi behandler følgende opplysninger om du er abonnent:
          </p>
          <p>
            - Navn*
          </p>
          <p>
            - Adresse*
          </p>
          <p>
            - Telefonnummer
          </p>
          <p>
            - Mobilnummer
          </p>
          <p>
            - Epostadresse*
          </p>
          <p>
            - Arbeidsgiver
          </p>
          <p>
            - Betalingsinformasjon som for eksempel fakturadresse
          </p>
          <p>
            - Informasjon om ditt kundeforhold, som for eksempel type abonnement og nyhetsbrev du har, betalingsinformasjon- og historikk
          </p>
          <p>
            - Bransjeinformasjon, dersom du har oppgitt dette til oss
          </p>
          <p>
            - Annen informasjon som du har gitt oss tilgang til eller som ligger åpent tilgjengelig på sosiale medier som Linkedin osv.
          </p>
          <p>
            4.2.2 Opplysninger som behandles om registrerte brukere
          </p>
          <p>
            - E-postadresse*
          </p>
          <p>
            - Navn*
          </p>
          <p>
            * = påkrevd for å fullføre registreringen
          </p>
          <p class="mt-5">
            <strong>5. Hvor opplysningene hentes fra</strong><br>
            Europower behandler personopplysninger om deg som du enten selv oppgir til oss eller vi får gjennom din bruk av Tjenestene, som vi får tilgang til fra enheten du bruker Tjenestene på.
          </p>
          <p class="mt-5">
            <strong>6. Vårt forhold til tredjeparter</strong>
          </p>
          <p>
            6.1 Hvem hjelper Europower med å behandle dine personopplysninger?
          </p>
          <p>
            For å kunne levere Tjenestene til deg, bruker Europower flere tjenester levert av tredjeparter. Dette innebærer at enkelte av disse tredjepartene behandler opplysninger om deg på våre vegne. Tredjeparter som behandler personopplysninger på vegne av Europower, kalles databehandlere. Oversikt over våre databehandlere finner du i vedlegg 2.
          </p>
          <p>
            Europower har databehandleravtale med tredjeparter som behandler personopplysninger på våre vegne i henhold til gjeldende lovkrav.
          </p>
          <p>
            6.2 Hvem utleverer Europower personopplysninger til?
          </p>
          <p>
            Europower bruker en rekke tjenester levert av tredjeparter som bruker informasjon om Europowers brukere uten å knytte slik informasjon til deg som enkeltperson. Europowers implementering av slike tjenester er gjort på en slik måte at disse ikke innebærer behandling eller overføring av personopplysninger om deg fra Europower. De Europower utleverer personopplysninger til skal ikke kunne bruke dine personopplysninger til å berike egne profilsett.
          </p>
          <p>
            Europower bruker Google AdX, Google DFP, og Google AdWords for å levere, tilpasse og måle effekten av annonser i Tjenestene. Vi sikrer at informasjonen som brukes i forbindelse med dette er anonym, og følger Googles Best practices to avoid sending Personally Identifiable Information (PII). Nærmere informasjon om Googles tjenester, teknologi og bruk av data finner du her. Du kan også velge bort innsamling og bruk av informasjon for annonsemålretting fra andre aktører her.
          </p>
          <p>
            Europower knytter ikke informasjonen fra nevnte tredjepartstjenester til din person og har implementert disse med hensyn til ditt personvern. Dersom du har egen brukerkonto hos nevnte tjenesteleverandører og er innlogget på slike brukerkontoer når du bruker Tjenestene, kan disse tjenesteleverandørene i enkelte tilfeller kunne knytte informasjon om din bruk av Tjenestene til din brukerkonto hos dem. For slik behandling er tjenesteleverandøren behandlingsansvarlig og deres vilkår gjelder. Du kan endre brukerinnstillingene dine knyttet til Google her. Google har også forklart hvordan du som sluttbruker kan fjerne uønskede annonser på Blokkér bestemte annonser.
          </p>
          <p>
            Aktiv interaksjon med slike tjenester, for eksempel ved å trykke på annonser og lenker, kan lede deg til nettsteder/applikasjoner/tjenester som eies av andre enn Europower. I slike tilfeller gjelder egne vilkår, og ikke Europowers.
          </p>
          <p class="mt-5">
            <strong>7. Sletting og arkivering av dine personopplysninger</strong><br>
            Dine personopplysninger vil kun bli lagret så lenge vi har saklig behov for dette i samsvar med denne personvernerklæringen og gjeldende lovgivning for øvrig. Når saklig behov ikke lenger foreligger, vil opplysningene bli slettet, kryptert eller anonymisert.
          </p>
          <p>
            7.1 For abonnenter, passive abonnenter og registrerte brukere:
          </p>
          <p>
            Europower vil slette alle personopplysninger som er lagret om deg automatisk etter maks 12 måneder etter kundeforholdet har opphørt, med mindre du har samtykket til å bli husket for en lengre periode i våre systemer.
          </p>
          <p>
            7.2 Regnskapsplikt og lignende lovkrav krever at visse opplysninger lagres for en lenger periode. I perioden frem til sletting av slike opplysninger vil det være begrenset adgang til slike data som sikres med passende tekniske og organisatoriske midler.
          </p>
          <p>
            7.3 For uregistrerte brukere:
          </p>
          <p>
            Personopplysninger som stammer fra kundehenvendelser fra uregistrerte brukere, vil bli slettet når de ikke lenger anses nødvendig for å oppfylle det formålet de ble samlet inn for. Det vil si at de slettes når det er usannsynlig at det vil komme flere henvendelser angående samme sak. Denne tidsfristen er satt til maks 12 måneder etter at saken er ferdigbehandlet av vår kundeservice.
          </p>
          <p class="mt-5">
            <strong>8. Dine rettigheter som registrert</strong><br>
            Du kan når som helst trekke tilbake ditt samtykke gitt etter denne personvernerklæringen eller på annen måte til Europower.
          </p>
          <p>
            Slik tilbaketrekking kan gjøres ved å kansellere eventuelle kundeforhold du har med oss hvor du ber oss om å få slettet din brukerkonto. For å slette anonymisert informasjon om din bruk av Tjenestene, må du i tillegg slette informasjonskapsler på enheten/nettleseren din. Tilbaketrekking av samtykket påvirker ikke lovligheten av behandlingen som bygger på samtykke før samtykket trekkes tilbake.
          </p>
          <p>
            Du kan også trekke tilbake ditt samtykke ved å kontakte Europower ved å gå inn på <a href="/kontaktoss/">www.europower.no/kontaktoss</a>. Du kan også kontakte Europowers personvernombud.
          </p>
          <p>
            Du kan også kontakte Europower på samme måte dersom du ønsker innsyn i hvilke av dine personopplysninger Europower har lagret, dersom du ønsker å få overført disse personopplysningene til deg eller å få disse opplysningene slettet eller rettet.
          </p>
          <p>
            På samme måte kan du ta kontakt med Europower dersom du ønsker å begrense Europowers behandling av dine personopplysninger eller dersom du ønsker å protestere mot Europowers behandling av dine personopplysninger. Du har også rett til å klage på vår behandling til Datatilsynet som tilsynsmyndighet hvis du mener vår behandling er i strid med lovverket.
          </p>
          <p>
            Du kan selv velge å fjerne vår tilgang til din enhets lokasjonsdata ved å slå av posisjonsverktøyet på enheten eller ved å nekte deling av slike data gjennom din enhets innstillinger.
          </p>
          <p>
            Dersom Europower oppdaterer eller endrer denne personvernerklæringen, vil Europower gjøre deg oppmerksom på endringene. Oversikt over tidligere versjoner av denne personvernerklæringen er tilgjengelig.
          </p>
          <p class="mt-5">
            <strong>9. Sikkerhet</strong><br>
            Europower har tekniske og organisatoriske sikkerhetstiltak, herunder rutiner for internkontroll og informasjonssikkerhet i henhold til gjeldende personvernregler i Norge.
          </p>
          <p>
            Det er trygt å bruke Tjenestene. Europower håndterer personopplysninger på en kryptert og sikker måte, blant annet ved bruk av HTTPS-kommunikasjon for sikker overføring av data om deg.
          </p>
          <p>
            Europower vil ikke selge, bytte eller videreformidle dine personopplysninger til tredjeparter utover det som fremgår av denne personvernerklæringen.
          </p>
        </div>
      </column>
    </row>
  </div>
</template>

<script>
import { Page } from 'global-components';
export default {
  name: 'privacy-page',
  extends: Page
};
</script>
